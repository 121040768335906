<template>
  <component
    :is="componentName(element, components)"
    v-for="element in page.elements"
    :key="element.id"
    :element="element"
  />
</template>

<script lang="ts" setup>
  import { defineAsyncComponent } from "vue"
  import { useNotificationStore } from "~/pinia/notifications"

  const props = defineProps<{
    page: AlchemyPage
  }>()

  const nStore = useNotificationStore()
  const { componentName } = useAlchemy()

  const components = {
    headline: defineAsyncComponent(
      () => import("~/alchemy/elements/headline.vue"),
    ),
    ifra_list: defineAsyncComponent(
      () => import("~/alchemy/elements/ifra_list.vue"),
    ),
  }

  useHead(usePageInfo(props.page))
  nStore.announce(props.page.title)
</script>
